.contact-container {
  display: flex;
  height: 100%;
  text-align: left;
  justify-content: left;
  align-items:center;
}

.text-center {
  width: 20%;
  margin-left: 10%;
  background: rgba(51, 51, 51, 0.4);
  padding: 15px;
  box-shadow: 0px 0px 10px 10px rgba(51, 51, 51, 0.4);
  align-items: center;
}

.text-center h1 {
  text-align: center;
}

@media (max-width: 1100px) {
  .contact-container {
    display: flex;
    height: 100%;
    text-align: left;
    justify-content: center;
    align-items:center;
  }

  .text-center {
    width: 100%;
  }
}

.contact-button {
  display: flex;
  flex-direction: row;

}

.contact-button h4 {
  margin-left: 20px;
  line-height: 40px;
}

