.blog-container-one {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 25px;
}

.text-width {
  width: 50%;
}

.image-banner {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
}

.banner-img {
  width: 100%;
  height: auto;
  object-fit: cover;
}

@media (max-width: 1200px) {
  .image-banner {
    grid-template-columns: repeat(3, 1fr);
  }

  .text-width {
    width: 80%;
  }
}

@media (max-width: 900px) {
  .text-width {
    width: 100%;
  }
}
