.detail-container {
  display: flex;
  height: 100%;
  text-align: left;
  justify-content: right;
  align-items:center;
}

.max-text {
  width: 50%;
  background: rgba(51, 51, 51, 0.4);
  padding: 15px;
  box-shadow: 0px 0px 10px 10px rgba(51, 51, 51, 0.4);
}

.max-text h1 {
  text-align: center;
}

@media (max-width: 1100px) {
  .detail-container {
    display: flex;
    height: 100%;
    text-align: left;
    justify-content: center;
    align-items:center;
  }

  .max-text {
    width: 100%;
  }
}

ul li::before {
  content: "";
    display: inline-block;
    width: 20px; /* set the width of the custom bullet point */
    height: 20px; /* set the height of the custom bullet point */
    background-image: url('./thunder.svg');
    background-size: contain; /* adjust as needed */
    margin-right: 10px; /* add some space between the bullet point and the list item */
}