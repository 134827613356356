.service-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  text-align: center;
}

.service-job {
  background-color: #111;
  border: 3px solid #ce353d;
  box-shadow: 0px 0px 10px 10px rgba(206, 53, 61, 0.4);
  border-radius: 10px;
  padding: 15px;
  margin: 20px 30px;
}

.service-text {
  text-align: center;
  margin-top: auto;
  margin-bottom: auto;
}

@media (max-width: 1200px) {
  .service-container {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 750px) {
  .service-container {
    grid-template-columns: 1fr;
  }
}