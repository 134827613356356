h1, h2, h3, h4, h5, p, li {
  color: #fff;
}

h1, h2, h3 {
  font-family: 'Caveat', cursive;
}

h4, h5, p, li {
  font-family: 'Montserrat', sans-serif;
}

ul {
  /* list-style: "🗲"; */
  list-style: none;
  /* padding-left: 1em; */
}

li {
  /* padding-left: 1em; */
  margin: 15px 0;
}

h1 {
  font-weight: bold;
  font-size: 3em;
}

@media (max-width: 1050px) {
  h1 {
    font-size: xx-large;
  }

  p {
    font-size: small;
  }

  li {
    font-size: medium;
  }
}

@media (max-width: 650px) {
  h1 {
    font-size: x-large;
  }

  /* p {
    font-size: x-small;
  } */

  li {
    font-size: small;
  }
}

a {
  text-decoration: none !important;
}